import './home.scss';

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
export default {
  name: 'index',
  components: {
  },
  data() {
    return {
      color: colorList[0],
      userInfo: {}
    };
  },
  created() {
    // this.userInfo = this.$store.getters.userInfo;
    
  },
  mounted() {
  },
  computed: {
    isLoading() {
      const arr = Object.keys(this.$store.getters.userInfo);
      if (arr.length > 0) {
        this.userInfo = this.$store.getters.userInfo;
        return false;
      }
    }
  },
  watch: {
    isLoading: {
      handler: function(val) {
        if (val) { // 已获取
          console.log('start...', val);
        }
      }
    }
  }
};
